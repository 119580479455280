import { type Dispatch, type SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useStateRef, useUpdateEffect } from "@remhealth/ui";
import { useSearchParamsRef } from "./useSearchParamsRef";

/**
 * Hook that helps ensure changes to a search parameter do not cause stacking on the history.
 * @param name The name of the URL search parameter.
 */
export function useSearchParamValue(name: string): [value: string, setValue: Dispatch<SetStateAction<string>>] {
  const navigate = useNavigate();

  const searchParams = useSearchParamsRef();

  const allowGoBack = useStateRef(searchParams.current.size === 0);

  const value = useStateRef(() => searchParams.current.get(name) ?? "");
  const currentValue = cleanValue(searchParams.current.get(name) ?? "");

  // Handle when browser changes query (e.g. user pressed Back)
  useUpdateEffect(() => {
    if (value.current !== currentValue) {
      handleSetValue(currentValue);
    }
  }, [currentValue]);

  return [value.current, handleSetValue];

  function handleSetValue(desiredValue: SetStateAction<string>) {
    const newValue = typeof desiredValue === "function" ? desiredValue(value.current) : desiredValue;
    setValue(cleanValue(newValue));
  }

  function setValue(newValue: string) {
    newValue = cleanValue(newValue);

    const currentValue = searchParams.current.get(name)?.trim() ?? "";

    if (currentValue !== newValue) {
      if (!newValue && canGoBack()) {
        // Return to no search param
        navigate(-1);
      } else {
        searchParams.set(searchParams => {
          if (newValue) {
            searchParams.set(name, newValue);
            return new URLSearchParams(searchParams);
          }

          if (searchParams.has(name)) {
            searchParams.delete(name);
            return new URLSearchParams(searchParams);
          }

          return searchParams;
        }, { replace: searchParams.current.size > 0 });

        allowGoBack.set(true);
      }
    }

    value.set(newValue);
  }

  function canGoBack() {
    if (allowGoBack.current) {
      return searchParams.current.size === 1 && searchParams.current.has(name);
    }

    return false;
  }
}

function cleanValue(value: string): string {
  return value.trim();
}
